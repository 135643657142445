<template>
    <b-row>
        <b-col cols="12" class="mb-18">
            <b-row align-v="center" align-h="between">
            </b-row>
        </b-col>
        <b-col cols="12" class="overflow-hidden">
            <apexchart type="bar" legend="legend" height="250" :options="options" :series="series"></apexchart>
        </b-col>
    </b-row>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import dashboardsService from "../../../../services/dashboards.service";
import { COLORSTONE2 } from "../../../../helpers/variables";

export default {
    name: 'TotalArticle5YearsChartComponent',
    data() {
        return {
            series: [],
            options: {}
        };
    },
    components: {
        BRow,
        BCol,
        BCard,
    },
    methods: {
        getTotalArticles: async function () {
            let response = await dashboardsService.getArticle5YearsChart();
            let articles = response.data;

            const result = {
                series: [],
                options: {
                chart: {
                    fontFamily: "Manrope, sans-serif",
                    type: "bar",

                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                labels: {
                    style: {
                        fontSize: "14px",
                    },
                },

                dataLabels: {
                    enabled: false,
                },

                grid: {
                    borderColor: "#DFE6E9",
                    row: {
                        opacity: 0.5,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 2,
                        columnWidth: "45%",
                        endingShape: "rounded",
                    },
                    colors: {
                        backgroundBarColors: COLORSTONE2,
                    },
                },

                stroke: {
                    show: true,
                    width: 4,
                    colors: ["transparent"],
                },
                xaxis: {
                    axisTicks: {
                        show: false,
                        borderType: "solid",
                        color: "#78909C",
                        height: 6,
                        offsetX: 0,
                        offsetY: 0,
                    },

                    tickPlacement: "between",
                    labels: {
                        style: {
                            colors: ["636E72"],
                            fontSize: "14px",
                        },
                    },
                    categories: [],
                },
                legend: {
                    horizontalAlign: "right",
                    offsetX: 40,
                    position: "top",
                    markers: {
                        radius: 12,
                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: ["636E72"],
                            fontSize: "14px",
                        },
                        formatter: (value) => {
                            return value ;
                        },
                    },

                    min: 0,
                    max: 100,
                    tickAmount: 5,
                },
            },
            };

            // Extract categories (year_issue)
            result.options.xaxis.categories = articles.map(item => item.year_issue);

            // Create a map to aggregate series data
            const seriesMap = {};

            // Populate the series data
            articles.forEach(item => {
                item.articles.forEach(article => {
                    if (!seriesMap[article.article_type_name]) {
                        seriesMap[article.article_type_name] = new Array(result.options.xaxis.categories.length).fill(0);
                    }
                    const index = result.options.xaxis.categories.indexOf(item.year_issue);
                    seriesMap[article.article_type_name][index] = article.count;
                });
            });

            // Convert seriesMap to series array
            result.series = Object.keys(seriesMap).map(key => ({
                name: key,
                data: seriesMap[key]
            }));

            this.series = result.series;
            this.options = result.options;
        }
    },
    mounted() {
        this.getTotalArticles();
    },
};
</script>