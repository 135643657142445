<template>
  <div>
    <LoaderComponent v-if="isLoading"/>
    <b-row class="mb-4">
      <b-col cols="12" md="4" class="mb-3">
        <TotalArticleComponent />
      </b-col>
      <b-col cols="12" md="4" v-for="item in totalArticleType" :key="item.id" class="mb-3">
        <TotalArticleTypeComponent :total="item.total" :typeName="item.articleTypeName" />
      </b-col>
      <b-col cols="12" md="4" class="mb-3" v-for="item in totalPublishType" :key="item.id">
        <TotalPublishOfTypeComponent :total="item.total" :typeName="item.publishTypeName" />
      </b-col>
    </b-row>

    <b-row class="mb-4">
      <b-col cols="12" lg="6" class="mb-3">
        <b-card>
          <b-row class="mb-2">
            <b-col>
              <h4>กราฟแสดงจำนวนประเภทงานวิจัย</h4>
            </b-col>
          </b-row>
          <TotalArticleOfTypeChartComponent />
        </b-card>
      </b-col>
      <b-col cols="12" lg="6" class="mb-3">
        <b-card>
          <b-row class="mb-2">
            <b-col>
              <h4>กราฟแสดงจำนวนประเภทการเผยแพร่</h4>
            </b-col>
          </b-row>
          <TotalpublishTypeChartComponent />
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mb-4">
      <b-col cols="12">
        <b-card>
          <b-row class="mb-2">
            <b-col>
              <h4>จำนวนบทความแยกตามหมวดหมู่</h4>
            </b-col>
          </b-row>
          <TotalCategoryChartComponent />
        </b-card>
      </b-col>
    </b-row>
    <br/>
    <b-row class="mb-4">
      <b-col cols="12">
        <b-card>
          <b-row class="mb-2">
            <b-col>
              <h4>สรุปรายรับรายจ่ายทั้งหมด</h4>
            </b-col>
          </b-row>
          <TotalIncomeExpensesComponent />
        </b-card>
      </b-col>
    </b-row>
    <br/>
    <b-row class="mb-4">
      <b-col cols="12">
        <b-card>
          <b-row class="mb-2">
            <b-col>
              <h4>จำนวนบทความ 5 ฉบับย้อนหลัง</h4>
            </b-col>
          </b-row>
          <TotalArticle5YearsChartComponent />
        </b-card>
      </b-col>
    </b-row>
    <br/>
    <b-row class="mb-4">
      <b-col cols="12">
        <b-card>
          <b-row class="mb-2">
            <b-col>
              <h4>จำนวนบทความจำแนกตามสังกัดผู้เขียน</h4>
            </b-col>
          </b-row>
          <TotalNumberOfArticlesClassifiedAuthorAffiliationChartComponent />
        </b-card>
      </b-col>
    </b-row>
    <br/>
    <b-row class="mb-4">
      <b-col cols="12">
        <b-card>
          <b-row class="mb-2">
            <b-col>
              <h4>สรุปจำนวนผู้ทรงคุณวุฒิประจำกองบรรณาธิการ</h4>
            </b-col>
          </b-row>
          <TableExpertComponent />
        </b-card>
      </b-col>
    </b-row>
    <br/>
    <b-row class="mb-4">
      <b-col cols="12">
        <b-card>
          <b-row class="mb-2">
            <b-col>
              <h4>สรุปจำนวนผู้ทรงคุณวุฒิประจำสาขา</h4>
            </b-col>
          </b-row>
          <TableAuthorComponent />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BDropdown, BDropdownItem } from "bootstrap-vue";
import dashboardsService from "../../../services/dashboards.service";
import TotalArticleComponent from './boxes/totalArticle.vue';
import TotalArticleTypeComponent from './boxes/totalArticleType.vue';
import TotalPublishOfTypeComponent from './boxes/totalPublishType.vue';
import TotalArticleOfTypeChartComponent from './graphs/totalArticleOfType.vue';
import TotalpublishTypeChartComponent from "./graphs/totalPublishOfType.vue";
import TotalCategoryChartComponent from "./graphs/totalCategory.vue";
import TotalArticle5YearsChartComponent from "./graphs/totalPrevious5Years.vue";
import TotalNumberOfArticlesClassifiedAuthorAffiliationChartComponent from "./graphs/totalNumberOfAffiliationAuthor.vue";
import TotalIncomeExpensesComponent from "./graphs/totalIncomeExpenses.vue";
import TableExpertComponent from "./tables/tableExpertEditorialDepartment.vue";
import TableAuthorComponent from "./tables/tableExpertMojor.vue";
import LoaderComponent from "../../components/loader/index.vue"


import PageContent from "@/layouts/components/content/page-content/PageContent.vue";
import articleTypeService from "../../../services/article-type.service";
import publishTypeService from "../../../services/publish-type.service";



export default {
  data() {
    return {
      isLoading : false,
      totalArticleType: [],
      totalPublishType: []
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BDropdown,
    BDropdownItem,
    TotalArticleComponent,
    TotalArticleOfTypeChartComponent,
    TotalArticleTypeComponent,
    TotalpublishTypeChartComponent,
    TotalPublishOfTypeComponent,
    TotalCategoryChartComponent,
    TotalArticle5YearsChartComponent,
    TotalNumberOfArticlesClassifiedAuthorAffiliationChartComponent,
    TableExpertComponent,
    TableAuthorComponent,
    TotalIncomeExpensesComponent,
    LoaderComponent

  },
  methods: {
    getArticleType: async function () {
      let response = await articleTypeService.getArticleType();
      let articleTypes = response.data;
      articleTypes.article_types.forEach(async (element) => {
        let responseArticleTypes = await dashboardsService.getTotalArticleTypeById(element.id);
        let articleType = responseArticleTypes.data;
        let newR = {
          articleTypeName: articleType.article_type_name,
          total: articleType.total_articles
        }
        this.totalArticleType.push(newR);
      });
    },
    getPublishType: async function () {
      let response = await publishTypeService.getPublishType();
      let publishType = response.data;
      publishType.publish_type.forEach(async (element) => {
        let responseArticleTypes = await dashboardsService.getTotalPublishTypeById(element.id);
        let articleType = responseArticleTypes.data;
        let newR = {
          publishTypeName: articleType.publish_type_name,
          total: articleType.total_articles
        }
        this.totalPublishType.push(newR);
      });
    },
  },
  mounted: async function () {
    let result1 = this.getArticleType();
    let result2 = this.getPublishType();
    this.isLoading = true;
    await Promise.all([result1, result2]);
    this.isLoading = false;
    // await this.getArticleTypeChart();
  },
};
</script>

<style scoped>
.mb-4 {
  margin-bottom: 1.5rem;
}
</style>
