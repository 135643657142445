<template>
  <div class="table-responsive">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th style="width: 25%;">ชื่อผู้ทรงคุณวุฒิประจำกองบรรณาธิการ</th>
                    <th v-for="issue in displayedIssues" :key="issue">{{ issue }}</th>
                    <th>รวม</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="inspector in paginatedInspectors" :key="inspector.inspector_name">
                    <td>{{ inspector.inspector_name }}</td>
                    <td v-for="issue in displayedIssues" :key="issue">
                        {{ getTotalArticlesForIssue(inspector.inspector_name, issue) }}
                    </td>
                    <td>{{ getSumOfArticles(inspector.inspector_name) }}</td>
                </tr>
            </tbody>
        </table>

        <!-- Pagination controls -->
        <br/>
        <nav v-if="totalPages > 1">
            <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <button class="page-link" @click="prevPage">ถัดไป</button>
                </li>
                <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                    <button class="page-link" @click="goToPage(page)">{{ page }}</button>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                    <button class="page-link" @click="nextPage">หน้าต่อไป</button>
                </li>
            </ul>
        </nav>
        <br/>
    </div>
</template>

<script>
import { BTable } from "bootstrap-vue";
import dashboardsService from "../../../../services/dashboards.service";

export default {
    name: 'TableinspectorComponent',
    data() {
        return {
            articles: [],
            currentPage: 1,
            perPage: 5 // Number of Inspectors to show per page
        };
    },
    components: {
        BTable,
    },
    computed: {
        InspectorsData() {
            const groupedInspectors = {};
            this.articles.forEach(article => {
                if (!groupedInspectors[article.inspector_name]) {
                    groupedInspectors[article.inspector_name] = [];
                }
                groupedInspectors[article.inspector_name].push(article);
            });
            return Object.keys(groupedInspectors).map(key => ({
                inspector_name: key,
                issues: groupedInspectors[key]
            }));
        },
        // Last 5 unique issues sorted by year descending
        displayedIssues() {
            const issues = Array.from(
                new Set(this.articles.map(article => article.year_issue))
            );
            return issues.slice(-5).reverse(); // Get last 5 issues
        },
        paginatedInspectors() {
            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;
            return this.InspectorsData.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.InspectorsData.length / this.perPage);
        }
    },
    methods: {
        getinspectorData: async function () {
            let response = await dashboardsService.getExpertEditorialDepartmentTable();
            let articles = response.data;
            this.articles = articles;
        },
        getTotalArticlesForIssue(inspectorName, issue) {
            const inspector = this.articles.find(
                article => article.inspector_name === inspectorName && article.year_issue === issue
            );
            return inspector ? inspector.total_articles : 0;
        },
        getSumOfArticles(inspectorName) {
            return this.articles
                .filter(article => article.inspector_name === inspectorName)
                .reduce((sum, article) => sum + parseInt(article.total_articles, 10), 0);
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        goToPage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
            }
        }
    },
    mounted() {
        this.getinspectorData();
    },

};
</script>