<template>
        <div class="overflow-hidden">
            <apexchart type="bar" legend="legend" height="350" :options="options" :series="series"></apexchart>
        </div>
</template>

<script>
import { BRow, BCol, BCard, BDropdown, BDropdownItem } from "bootstrap-vue";
import dashboardsService from "../../../../services/dashboards.service";

export default {
    name: "TotalNumberOfArticlesClassifiedAuthorAffiliationChartComponent",
    data() {
        return {
            series: [],
            options: {}
        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BDropdown,
        BDropdownItem,
    },
    methods: {
        getTotalArticleAffiliation: async function () {
            let response = await dashboardsService.getAuthorAffiliationChart();
            let affiliation = response.data;

            const series = affiliation.datasets.map(dataset => ({
                name: dataset.name,
                data: dataset.data.map(Number) // Convert string numbers to integers
            }));

            // Create the options object
            const options = {
                chart: {
                    fontFamily: "Manrope, sans-serif",
                    type: "bar",
                    toolbar: { show: false },
                    zoom: { enabled: false },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    },
                },
                labels: {
                    style: {
                        fontSize: "14px",
                    },
                },
                dataLabels: { enabled: false },
                grid: {
                    borderColor: "#DFE6E9",
                    row: { opacity: 0.5 },
                },
                fill: {
                    opacity: 1,
                    type: "solid",
                },
                stroke: {
                    show: true,
                    width: 4,
                    curve: "straight",
                    colors: ["transparent"],
                },
                xaxis: {
                    axisTicks: { show: false },
                    tickAmount: 5,
                    labels: {
                        style: {
                            colors: ["636E72"],
                            fontSize: "14px",
                        },
                    },
                    categories: affiliation.labels,
                },
                legend: {
                    horizontalAlign: "right",
                    offsetX: 40,
                    position: "top",
                    markers: {
                        radius: 12,
                    },
                },
                colors: ["#0063F7"],
                yaxis: {
                    labels: {
                        style: {
                            colors: ["636E72"],
                            fontSize: "14px",
                        },
                    },
                },
            };


            this.options = options;
            this.series = series;

        }
    },
    mounted() {
        this.getTotalArticleAffiliation();
    },
};
</script>