<template>
    <b-row>
        <b-col cols="12" class="mb-18">
            <b-row align-v="center" align-h="between">
            </b-row>
        </b-col>
        <b-col cols="12" class="overflow-hidden">
            <apexchart type="bar" legend="legend" height="250" :options="options" :series="series"></apexchart>
        </b-col>
    </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import dashboardsService from "../../../../services/dashboards.service";
import { COLORSTONE2 } from "../../../../helpers/variables";

export default {
    name: 'TotalCategoryChartComponent',
    data() {
        return {
            series: [],
            options: {
                chart: {
                    fontFamily: "Manrope, sans-serif",
                    type: "bar",
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                labels: {
                    style: {
                        fontSize: "14px",
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                grid: {
                    borderColor: "#B2BEC3",
                    opacity: 1,
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 2,
                        columnWidth: "60%",
                        colors: {
                            backgroundBarColors: ["#B2BEC3"],
                            backgroundBarOpacity: 0.2,
                        },
                    },
                },
                fill: {
                    opacity: 1,
                    colors: [
                        this.$store.state.themeConfig.theme === "light"
                            ? "#2D3436"
                            : "#ffffff",
                    ],
                },
                stroke: {
                    show: true,
                    width: 4,
                    colors: ["transparent"],
                },
                xaxis: {
                    axisTicks: {
                        show: false,
                        borderType: "solid",
                        height: 6,
                        offsetX: 0,
                        offsetY: 0,
                    },
                    tickPlacement: "between",
                    labels: {
                        style: {
                            colors: ['#2D3436'],
                            fontSize: "12px",
                        },
                    },
                    categories: [],
                },
                legend: {
                    horizontalAlign: "right",
                    offsetX: 40,
                    position: "top",
                    markers: {
                        radius: 12,
                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: ["#636E72"],
                            fontSize: "14px",
                        },
                        formatter: (value) => {
                            return value 
                        },
                    },
                    min: 0,
                    max: 100,
                    tickAmount: 4,
                },
            },
        };
    },
    components: {
        BRow,
        BCol,
    },
    methods: {
        getTotalCategory: async function () {
            let response = await dashboardsService.getArticleCategoriesChart();
            let articleTypes = response.data;
            this.series.push( {
                    name: "จำนวนบทความ",
                    data: articleTypes.values,
                });
            this.options.xaxis.categories.push(...articleTypes.categories);
        }
    },
    mounted: async function () {
        await this.getTotalCategory();
    },
};
</script>