<template>
    <div class="overflow-hidden">
        <apexchart type="donut" legend="legend" height="350" :options="options" :series="series"></apexchart>
    </div>
</template>

<script>
import { BRow, BCol, BCard, BDropdown, BDropdownItem } from "bootstrap-vue";
import dashboardsService from "../../../../services/dashboards.service";

export default {
    name: 'TotalIncomeExpensesComponent',
    data() {
        return {
            series: [0, 0], // Default values
            options: {},
            income: 0,
            expenses: 0,
        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BDropdown,
        BDropdownItem,
    },

    methods: {
        getTotalFinancial: async function () {
            let response = await dashboardsService.getFinancialChart();
            let financial = response.data;
            // Convert string values to numbers
            this.income = parseFloat(financial.Income) || 0;
            this.expenses = parseFloat(financial.Expenses) || 0;

            this.series = [this.income, this.expenses];
            this.options = {
                chart: {
                    fontFamily: "Manrope, sans-serif",
                    type: "donut",
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                colors: ["#0010F7", "#55B1F3"],
                labels: ["รายรับ", "รายจ่าย"],
                dataLabels: {
                    enabled: true,
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: "85%",
                            labels: {
                                show: true,
                                name: {
                                    fontSize: "2rem",
                                },
                                value: {
                                    fontSize: "16px",
                                    formatter(val) {
                                        if (val === undefined || val === null) {
                                            return '0 บาท'; // Default value if undefined
                                        }
                                        return `${parseFloat(val).toLocaleString()} บาท`;
                                    },
                                },
                                total: {
                                    show: true,
                                    fontSize: "16px",
                                    label: "รวม",
                                    formatter: function (w) {
                                        const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                                        return `${total.toLocaleString()} บาท`;
                                    },
                                },
                            },
                        },
                    },
                },
                legend: {
                    itemMargin: {
                        horizontal: 24,
                        vertical: 0,
                    },
                    horizontalAlign: "center",
                    position: "bottom",
                    fontSize: "14px",
                    markers: {
                        radius: 12,
                    },
                },
            }
        }
    },
    mounted() {
        this.getTotalFinancial();
    },
};
</script>