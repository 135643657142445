<template>
  <b-card class="hp-card-1" body-class="p-16 d-flex align-items-center">
    <b-row align-v="center" class="flex-shrink-1 w-100 mx-0">
      <b-col  class="px-0">
        <div class="card-body py-16">
            <i class="hp-text-color-dark-0 ri-3x ri-bar-chart-2-fill"></i>
          </div>
      </b-col>
      <b-col class="pr-0">
        <h3 class="mb-0 font-weight-semibold">{{ totalArticle }}</h3>
        <p class="hp-p1-body mb-0">บทความ</p>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { BRow, BCol, BCard, BDropdown, BDropdownItem } from "bootstrap-vue";
import dashboardsService from "../../../../services/dashboards.service";

export default {
  name: 'TotalArticleComponent',
  data() {
    return {
      series: [
        {
          name: "Earning",
          data: [50, 70, 100, 60],
        },
      ],
      options: {
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 10,
            top: -10,
            bottom: -10,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "65%",
            borderRadius: 2,
            colors: {
              backgroundBarColors: [],
              backgroundBarRadius: 5,
            },
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#0010F7"],
        xaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          max: 100,
        },
        tooltip: {
          x: {
            show: false,
          },
        },
      },
      totalArticle: 0
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BDropdown,
    BDropdownItem,
  },
  methods: {
    getTotalArticle: async function () {
      this.series = [];
      let response = await dashboardsService.getTotalArticle();
      let article = response.data;
      this.totalArticle = article.total_articles;
    }
  },
  mounted: async function () {
    await this.getTotalArticle();
  },
};
</script>