<template>
    <div class="overflow-hidden">
         <apexchart type="donut" legend="legend" height="350" :options="options" :series="series"></apexchart>
       </div>
   </template>
   <script>
   import { BRow, BCol, BCard, BDropdown, BDropdownItem } from "bootstrap-vue";
   import dashboardsService from "../../../../services/dashboards.service";
 import { COLORSTONE1 } from "../../../../helpers/variables";
   
   export default {
     name: 'TotalpublishTypeChartComponent',
     data() {
     return {
       series: [],
       options: {
         chart: {
           fontFamily: "Manrope, sans-serif",
           type: "donut",
           toolbar: {
             show: false,
           },
           zoom: {
             enabled: false,
           },
         },
         colors: COLORSTONE1,
 
         labels: [],
 
         dataLabels: {
           enabled: false,
         },
         plotOptions: {
           pie: {
             donut: {
               size: "85%",
               labels: {
                 show: true,
                 name: {
                   fontSize: "2rem",
                 },
                 value: {
                   fontSize: "16px",
                   formatter(val) {
                     return ` ${val} บทความ`;
                   },
                 },
                 total: {
                   show: true,
                   fontSize: "16px",
                   label: "รวมบทความทั้งหมด",
                   // formatter(w) {
                   //   return `$ `;
                   // },
                   formatter: function (w) {
                     return ` ${w.globals.seriesTotals.reduce((a, b) => {
                       return a + b;
                     }, 0)} บทความ`;
                   },
                 },
               },
             },
           },
         },
 
         legend: {
           itemMargin: {
             horizontal: 24,
             vertical: 0,
           },
           horizontalAlign: "center",
           position: "bottom",
           fontSize: "14px",
 
           markers: {
             radius: 12,
           },
         },
       },
     };
   },
     components: {
       BRow,
       BCol,
       BCard,
       BDropdown,
       BDropdownItem,
     },
     methods: {
     getTotalPublishType: async function () {
         this.series = [];
         let response = await dashboardsService.getPublishTypeChart();
         let publishTypes = response.data;
         this.series = publishTypes.values;
         this.options.labels.push(...publishTypes.labels);
       }
     },
     mounted: async function() {
       await this.getTotalPublishType();
     },
   };
   </script>
   