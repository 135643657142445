import { CONSTANTS } from "../helpers/variables";
import axiosService from "./axios.service";

class DashboardService {
    async getArticleTypeChart() {
        let articles = await axiosService.get(CONSTANTS.APIURL + `article-type-chart`);
        return articles;
    }
    async getPublishTypeChart() {
        let publish = await axiosService.get(CONSTANTS.APIURL + `publish-type-chart`);
        return publish;
    }
    async getTotalArticle() {
        let totalOfArticle = await axiosService.get(CONSTANTS.APIURL + `article-total`);
        return totalOfArticle;
    }
    async getTotalArticle() {
        let totalOfArticle = await axiosService.get(CONSTANTS.APIURL + `article-total`);
        return totalOfArticle;
    }
    async getTotalArticleTypeById(articleTypeId) {
        let totalOfArticleType = await axiosService.get(CONSTANTS.APIURL + `article-total/by-type-id?article_type_id=${articleTypeId}`);
        return totalOfArticleType;
    }
    async getTotalPublishTypeById(publishTypeId) {
        let totalOfPublishType = await axiosService.get(CONSTANTS.APIURL + `publish-total/by-id?publish_type_id=${publishTypeId}`);
        return totalOfPublishType;
    }
    async getArticleCategoriesChart() {
        let categories = await axiosService.get(CONSTANTS.APIURL + `articles-total/by-category`);
        return categories;
    }

    async getArticle5YearsChart() {
        let articles = await axiosService.get(CONSTANTS.APIURL + `articles-total/by-5years`);
        return articles;
    }
    async getAuthorAffiliationChart() {
        let affiliation = await axiosService.get(CONSTANTS.APIURL + `articles-total/by-author-affiliation`);
        return affiliation;
    }

    async getExpertEditorialDepartmentTable() {
        let experts = await axiosService.get(CONSTANTS.APIURL + `articles-total/by-inspector-table`);
        return experts;
    }
    async getExpertMajorTable() {
        let experts = await axiosService.get(CONSTANTS.APIURL + `articles-total/by-expert-table`);
        return experts;
    }

    async getFinancialChart() {
        let experts = await axiosService.get(CONSTANTS.APIURL + `financial-chart`);
        return experts;
    }
   
}
export default new DashboardService();