<template>
    <div class="table-responsive">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>วารสาร</th>
                    <th>ชื่อบทความ</th>
                    <th>ผู้ทรงคุณวุฒิ</th>
                    <th>สังกัด (ผู้ทรงคุณวุฒิ)</th>
                    <th>ผู้เขียน</th>
                    <th>สังกัด (ผู้เขียน)</th>
                    <!-- <th>อีเมล (ผู้เขียน)</th>
            <th>โทรศัพท์ (ผู้เขียน)</th> -->
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in paginatedData" :key="index">
                    <td>{{ row.year_issue }}</td>
                    <td>{{ row.article_name }}</td>
                    <td>{{ row.expert_name.map(expert => expert.experts).join(', ') }}</td>
                    <td>{{ row.expert_name.map(expert => expert.affiliation_th).join(', ') }}</td>
                    <td>{{ row.author_name.map(author => author.authors).join(', ') }}</td>
                    <td>{{ row.author_name.map(author => author.affiliation_th).join(', ') }}</td>
                    <!-- <td>{{ row.author_name.map(author => author.email).join(', ') }}</td>
            <td>{{ row.author_name.map(author => author.number_phone).join(', ') }}</td> -->
                </tr>
            </tbody>
        </table>

        <!-- Pagination Controls with Bootstrap -->
        <!-- <nav v-if="totalPages > 1" aria-label="Page navigation">
        <ul class="pagination justify-content-center">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" @click.prevent="prevPage">Previous</a>
          </li>
          <li class="page-item disabled">
            <span class="page-link">Page {{ currentPage }} of {{ totalPages }}</span>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
          </li>
        </ul>
      </nav> -->

        <!-- Pagination controls -->
        <br/>
        <nav v-if="totalPages > 1">
            <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <button class="page-link" @click="prevPage">ถัดไป</button>
                </li>
                <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                    <button class="page-link" @click="goToPage(page)">{{ page }}</button>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                    <button class="page-link" @click="nextPage">หน้าต่อไป</button>
                </li>
            </ul>
        </nav>
        <br/>
    </div>
</template>



<script>
import { BTable } from "bootstrap-vue";
import dashboardsService from "../../../../services/dashboards.service";

export default {
    name: "TableAuthorComponent",
    data() {
        return {
            reportData: [],
            currentPage: 1, // Current page
            perPage: 5 // Number of rows per page
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.reportData.length / this.perPage); // Calculate total pages
        },
        paginatedData() {
            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;
            return this.reportData.slice(start, end); // Paginate data
        }
    },
    methods: {
        getExpertData: async function () {
            let response = await dashboardsService.getExpertMajorTable();
            let articles = response.data;
            this.reportData = articles;
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        goToPage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
            }
        }
    },
    mounted () {
        this.getExpertData();
    }
};
</script>
