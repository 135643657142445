<template>
    <b-card class="hp-card-1" body-class="p-16 d-flex align-items-center">
        <b-row align-v="center" class="flex-shrink-1 w-100 mx-0">
            <b-col class="px-0">
                <div class="card-body py-16">
                    <i class="hp-text-color-dark-0 ri-3x ri-bar-chart-2-fill"></i>
                </div>
            </b-col>
            <b-col class="pr-0">
                <h3 class="mb-0 font-weight-semibold"> {{ total }}</h3>
                <p class="hp-p1-body mb-0">{{ typeName }}</p>
            </b-col>
        </b-row>
    </b-card>
</template>
<script>
import { BRow, BCol, BCard, BDropdown, BDropdownItem } from "bootstrap-vue";

export default {
    name: 'TotalArticleTypeComponent',
    props: {
        total: Number,
        typeName: String,
    },
    data() {
        return {
            
        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BDropdown,
        BDropdownItem,
    },
    methods: {
    },
    mounted () {
    },
};
</script>